import React from "react";
import { Link } from "gatsby";
import Image from "@components/Image";
import styled from "styled-components";

interface PostProps {
  title: string;
  description: string;
  slug: string;
  image: any;
}

const StyledPost = styled.article`
  display: flex;
  margin: 80px 0;
  flex-direction: column;
`;

const PostTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 700;
  font-size: 37px;
  color: #343434;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #008eff;
  }
`;

const PostTitleWrapper = styled(Link)`
  text-decoration: none;
`;

const PostDescription = styled.p``;

const PostImage = styled.div`
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  max-height: 350px;
  height: 100%;
  width: 100%;
  img {
    object-fit: cover;
    object-position: center center;
    transition: all 0.3s ease-in-out !important;
    height: 100%;
    width: 100%;
    max-height: 350px;
  }
`;

const PostImageWrapper = styled(Link)`
  position: relative;
`;

const PostImageDesign = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 0;
  height: 100%;
  width: 100%;
  max-height: 350px;
`;

const PostImageBgDesign = styled.div`
  position: absolute;
  top: -20px;
  left: -20px;
  transition: all 0.3s ease-in-out;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  max-height: 350px;
  background: blue;
`;

const PostDetails = styled.div`
  margin-top: 30px;
  padding-right: 30px;
`;

const Post: React.FC<PostProps> = ({
  title,
  description,
  slug,
  image,
}) => {
  return (
    <StyledPost>
      <PostImageWrapper to={`/blog${slug}`}>
        <PostImageBgDesign />
        <PostImage>
          <PostImageDesign />
          <Image src={image} alt="image" />
        </PostImage>
      </PostImageWrapper>
      <PostDetails>
        <PostTitleWrapper to={`/blog${slug}`}>
          <PostTitle>{title}</PostTitle>
        </PostTitleWrapper>
        <PostDescription>{description}</PostDescription>
      </PostDetails>
    </StyledPost>
  );
};

export default Post;
