import React from "react";
import Img from "gatsby-image";

interface ImageProps {
  title?: string;
  alt: string;
  src: any;
}

const Image: React.FC<ImageProps> = ({ alt, title, src }) => {
  if (!!src && !!src.childImageSharp) {
    return (
      <Img
        fluid={src.childImageSharp.fluid}
        alt={alt}
        title={title}
      />
    );
  }

  if (!!src && typeof src === "string")
    return <img src={src} alt={alt} title={title} />;

  return null;
};

export default Image;
